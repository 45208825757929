<template>
  <div class="container-top">
    <div class="pc container padding-top-60">
      <div style="text-align:center">
        <img src="/static/icon/u_check-circle.svg" class="svg-primary"
             style="width:54px;height:54px;margin-bottom: 32px">
        <div class="h4 main">결제가 완료되었습니다.</div>
        <div class="body2-web sub2" style="margin:12px 0 32px 0">이메일로 결제 관련 안내문이 발송될 예정입니다.</div>
        <div class="box-warning">
          <svg-icon icon="warning" color="subcolor2"></svg-icon>
          <div class="body4-medium subcolor2 text-center">추가 수정작업이 완료되면 메일로 알려드립니다. <br>
            예상 작업기간 : 결제일로 부터 1달
          </div>

        </div>
      </div>
      <div class="flex-center" style="gap:12px">
        <button @click="clickDetail"
                class="button is-gray body2-medium" style="width:200px;height:48px">결제 내역</button>
        <button @click="clickService"
          class="button is-gray body2-medium" style="width:200px;height:48px">내 서비스 목록</button>
      </div>

      <div class="box-title">결제 정보</div>
      <div class="flex" style="flex-wrap: wrap">
        <div class="col-3 item-col subtitle5 main">총 결제금액</div>
        <div class="col-9 item-col body2 sub"><span class="h5 main price">{{ order.price|currencyNum }}</span>원</div>
        <div class="col-3 item-col subtitle5 main">결제 수단</div>
        <div class="col-9 item-col body2 sub">{{ order.credit }}</div>
      </div>
    </div>
    <div class="mobile">
      <div class="mo-container">
        <div class="text-center">
          <img src="/static/icon/u_check-circle.svg" class="svg-primary"
               style="width:40px;height:40px;margin-bottom: 12px">
          <div class="h7 main">결제가 완료되었습니다.</div>
          <div class="body4 sub2 margin-top-12">이메일로 결제 관련 안내문이 발송될 예정입니다.</div>
        </div>
        <div class="box-warning">
          <svg-icon icon="warning" color="subcolor2"></svg-icon>
          <div class="body5-medium subcolor2 text-center">추가 수정작업이 완료되면 메일로 알려드립니다. <br>
            예상 작업기간 : 결제일로 부터 1달
          </div>
        </div>

        <div class="flex-center" style="gap:12px">
          <button @click="clickDetail"
                  class="button is-gray" style="flex:1;height:44px">결제 내역</button>
          <button @click="clickService"
                  class="button is-gray" style="flex:1;height:44px">내 서비스 목록</button>
        </div>
      </div>

      <div class="mo-container">
        <div class="h8 main" style="margin-bottom:4px">결제정보</div>
        <div class="flex" style="flex-wrap: wrap">
          <div class="col-3 item-col body4-medium main">총 결제금액</div>
          <div class="col-9 item-col body4 sub"><span class="body4-bold main price">{{ order.price|currencyNum }}</span>원</div>
          <div class="col-3 item-col body4-medium main">결제 수단</div>
          <div class="col-9 item-col body4 sub">{{ order.credit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from "../component/SvgIcon";
  export default {
    name: "ServiceAccountDone",
    components: {SvgIcon},
    created() {

    },
    data() {
      return {}
    },
    methods: {
      clickDetail() {
        window.open(`${this.order.service.domain}/office/admin/service/plan`,'_blank');
      },
      clickService() {
        this.$router.replace(`/mypage/my_service`);
      }
    },
    computed: {
      order() {
        return this.$store.getters.accountDone;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .box-info
      background-color $gray3
      padding 32px
      padding-bottom 20px
      border-radius 8px
      width 698px
      margin 32px auto 16px auto
      text-align left
    .img-product
      width 140px
      height 105px
      border-radius 6px
      object-fit cover

    .box-title
      padding 16px 0
      border-bottom 1px solid $sub5
      font-weight 700
      font-size 18px
      line-height 24px
      margin-top 70px

    .item-col
      padding-top 24px

    .lp-divider-gray1
      height 1px
      background-color $gray1
      width 100%
      margin 20px 0

    .box-warning
      width 698px
      margin 16px auto 32px auto
      background-color $subcolor50
      padding 12px 16px
      border-radius 8px
      display flex
      justify-content center
      gap 8px

  .mo-container
    padding 24px 16px
    border-bottom 8px solid $gray2
  .mo-container:first-child
    padding 32px 16px
  .mo-container:last-child
    border-bottom 0

  .mobile
    .item-col
      padding 0
      margin-top 12px
    .box-info
      display block
      background-color $gray3
      padding 20px 28px
      border-radius 8px
      margin 24px 0
      text-align left
    .img-product
      width 120px
      height 90px
      border-radius 6px
      object-fit cover
    .lp-divider-gray1
      height 1px
      background-color $gray1
      width 100%
      margin 16px 0
    .box-warning
      width 100%
      margin 8px 0 24px 0
      background-color $subcolor50
      padding 12px 16px
      border-radius 8px
      display flex
      justify-content center
      gap 8px
</style>
